import Axios from 'axios';
import { Dropbox as DropboxInstance } from 'dropbox';
import { getMimeByFilename } from '../../components/file_upload/file_upload_helpers';

const FILE_SIZE_LIMIT = 1024 * 1024;

export class FilesService {
  limit;

  constructor(limit = FILE_SIZE_LIMIT) {
    this.limit = limit;
  }

  /**
   * Checks if file will be uploaded to Dropbox
   * @param {Number} fileSize
   * @return {boolean}
   */
  isDropboxUsed = (fileSize) => {
    return fileSize > this.limit;
  };

  /**
   * Checks if uploaded to Dropbox file is normal
   * @param {Object} file
   * @return {boolean}
   */
  isUploadOk = (file) => {
    return Object.keys(file).length > 2 || !this.isDropboxUsed(file.size);
  };

  /**
   * Gets the download link of the file
   * @param {String} filePath
   * @return {Promise<String>}
   */
  async getDownloadLink(filePath) {
    const res = await Axios({
      url: `/api/link/?file=${filePath}`,
      method: 'GET',
    });
    return res.data;
  }

  /**
   * Uploads file to Dropbox or Server
   * @param {File} file
   * @param {{
   *   id: String,
   *   folder: String,
   *   location: String,
   *   index: String,
   * }} query
   * @param {{
   *   wire_name: String,
   *   prev_ws_manual_file_name: String,
   *   ws_id: String,
   *   wsg_rev: String,
   * } | {
   *   replace: String,
   *   wsg_rev: String,
   *   wsg_manual_id: String,
   * } | {
   *   wsg_rev: String,
   * } | {
   *   replace: String,
   *   wsg_rev: String,
   * }} [params]
   * @param {'add' | 'overwrite' | 'update'} [mode]
   *
   * @return {Promise<File>}
   */
  async uploadFile(file, query, params, mode = 'add') {
    const original_filename = file.name;
    const mime_type = file.type === '' ? getMimeByFilename(file.name) : file.type;

    const isDropboxUsed = this.isDropboxUsed(file.size);
    const uploadedFile = isDropboxUsed
      ? await this.uploadToDropbox(file, query, params, mode)
      : await this.uploadToServer(file, query, params);

    return { ...uploadedFile, original_filename, mime_type };
  }

  async uploadToServer(file, query, params) {
    const queryParams = new URLSearchParams(query);
    const url = `/api/upload/?${queryParams.toString()}`;
    const formData = new FormData();
    for (const k in params) {
      formData.append(k, params[k]);
    }
    formData.append('file', file);
    return await Axios.post(url, formData).then(res => res.data.data);
  }

  async uploadToDropbox(file, query, params, mode) {
    const queryParams = new URLSearchParams(query);
    queryParams.set('file', file.name.replace(/#/g, ''));
    for (const k in params) {
      queryParams.set(k, params[k]);
    }
    const url = `/api/dbx/?${queryParams.toString()}`;
    const fileUploadData = await Axios.get(url).then(res => res.data.data);

    const dbx = new DropboxInstance({ accessToken: fileUploadData.token });

    let uploaded;
    if (file.size < this.limit) {
      uploaded = await dbx.filesUpload({ path: fileUploadData.path, contents: file, mode });
    } else {
      const chunks = [];
      let offset = 0;
      while (offset < file.size) {
        const chunkSize = Math.min(this.limit, file.size - offset);
        chunks.push(file.slice(offset, offset + chunkSize));
        offset += chunkSize;
      }

      const fileUploadTask = chunks.reduce((acc, contents, i) => {
        const isFirstChunk = i === 0;
        const isLastChunk = i === chunks.length - 1;
        if (isFirstChunk) {
          return acc.then(() =>
            dbx
              .filesUploadSessionStart({ close: false, contents })
              .then(({ session_id }) => session_id),
          );
        } else if (isLastChunk) {
          return acc.then(session_id => {
            const cursor = { session_id, offset: file.size - contents.size };
            const commit = { path: fileUploadData.path, mode: 'add', autorename: true, mute: false };
            return dbx.filesUploadSessionFinish({ cursor, commit, contents });
          });
        }
        // otherwise is a chunk between first and last chunks
        return acc.then(session_id => dbx.filesUploadSessionAppendV2({
          cursor: { session_id, offset: i * this.limit },
          close: false,
          contents,
        }).then(() => session_id));
      }, Promise.resolve());

      uploaded = await fileUploadTask;
    }
    return uploaded && uploaded.name ? fileUploadData : {};
  }
}

export const FilesServiceInstance = new FilesService();
